import capitalize from 'lodash/capitalize';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';
import { StreamChat } from 'stream-chat';
export default {
  setApiToken: (state, api_token) => {
    state.session.token = api_token;
    state.session.logged = true;
  },
  setStreamClient: (state, payload) => {
    state.streamUserToken = payload.token;
    state.streamClient = StreamChat.getInstance(payload.key);
  },
  setGiphyKey: (state, payload) => {
    state.giphyApiKey = payload;
  },
  updateStreamUser: (state, payload) => {
    state.streamUser = payload;
  },
  setUnreadChannelsCount: (state, payload) => {
    state.unreadChannelsCount = payload;
  },
  setImageForPreview: (state, payload) => {
    state.previewImage = payload;
  },
  setStreamCompanies: (state, payload) => {
    state.streamCompanies = payload;
  },
  setChannels: (state, payload) => {
    state.channels = payload.channels;
    state.rejectedChannels = payload.rejectedChannels;
    state.mutedChannels = payload.mutedChannels;
  },
  setChannelLoaded: state => {
    state.channelsLoaded = true;
  },
  addMutedChannel: (state, payload) => {
    state.mutedChannels.push(payload);
  },
  removeMutedChannel: (state, payload) => {
    state.mutedChannels = state.mutedChannels.filter(c => c !== payload);
  },
  setFolders: (state, payload) => {
    state.folders = payload;
  },
  updateFolder: (state, payload) => {
    let cIndex = state.folders.findIndex(f => f.folder_id === payload.folder_id);
    state.folders.splice(cIndex, 1);
    state.folders.push(payload);
  },
  deleteFolder: (state, payload) => {
    let cIndex = state.folders.findIndex(f => f.folder_id == payload);
    state.folders.splice(cIndex, 1);
  },
  setStreamUsers: (state, payload) => {
    state.streamUsers = payload;
  },
  removeStreamChannel: (state, payload) => {
    let cIndex = state.channels.findIndex(c => c.cid === payload);
    if (cIndex !== -1) {
      state.channels.splice(cIndex, 1);
    }
  },
  addStreamChannel: (state, payload) => {
    if (payload) {
      let cIndex = state.channels.findIndex(c => c.cid === payload.cid);
      if (cIndex === -1) {
        state.channels.push(payload);
      } else {
        state.channels.splice(cIndex, 1);
        state.channels.push(payload);
      }
    }
  },
  updateStreamChannel: (state, payload) => {
    let cIndex = state.channels.findIndex(c => c.cid === payload.cid);
    if (cIndex != -1) {
      state.channels[cIndex] = payload;
    }
  },
  setRequestChannels: (state, payload) => {
    state.requestChannels = payload;
  },
  updateStreamMessages: (state, payload) => {
    let cIndex = state.channels.findIndex(c => c.cid === payload.channelCid);
    if (cIndex != -1) {
      let formatMessages = payload.messages.map(x => {
        return { ...x, created_at: new Date(x.created_at) };
      });
      state.channels[cIndex].state.messages = formatMessages;
    }
  },
  setMessagingAccess: (state, payload) => {
    state.messagingAccess = payload;
  },
  setTermsOfServiceStatus: (state, payload) => {
    state.termsOfServiceStatus = payload;
  },
  setCompalianceUser: (state, payload) => {
    state.onlyCompliance = payload;
  },
  setUser: (state, user) => {
    state.session.logged = user.user?.id ? true : false;
    state.session.roles = user.roles;
    state.session.permissions = user.permissions;
    state.session.team_name = user.team_name;
    state.session.user = user.user;
  },
  setCoins: (state, coins) => {
    let actualSentimentColumns = [
      'hourly_sentiment',
      'daily_sentiment_avg_30_days',
      'daily_sentiment_change_7_days',
      'daily_sentiment',
      'long_term_sentiment',
      'daily_sentiment_momentum',
      'daily_sentiment_momentum_7_days'
    ];
    state.data.coins = coins.map(x => {
      if (x.tweet_volume < 12) {
        actualSentimentColumns.forEach(i => (x[i] = null));
      }
      return x;
    });
  },
  setSectorDatapoints: state => {
    if (state.coinDatapointsConfig.length) {
      const nameColumn = [
        { id: 'sector', type: 'sector', size: 4, name: 'Sector', default: true, enabled: true, index: 1 }
      ];
      state['sectorDatapointsConfig'] = nameColumn.concat(
        state.coinDatapointsConfig
          .filter(x => !x.default && !state.customScreenersIgnoreList.includes(x.id))
          .map(x => {
            let definition = state.metricTooltips.find(m => 'sector_' + x.id == m.metric_key)?.definition || '';
            return { ...x, definition: definition };
          })
      );
    }
  },
  setEcosystemDatapoints: state => {
    if (state.coinDatapointsConfig.length) {
      const nameColumn = [
        { id: 'ecosystem', type: 'ecosystem', size: 4, name: 'Ecosystem', default: true, enabled: true, index: 1 }
      ];
      state['ecosystemDatapointsConfig'] = nameColumn.concat(
        state.coinDatapointsConfig
          .filter(x => !x.default && !state.customScreenersIgnoreList.includes(x.id))
          .map(x => {
            let definition = state.metricTooltips.find(m => 'ecosystem_' + x.id == m.metric_key)?.definition || '';
            return { ...x, definition: definition };
          })
      );
    }
  },
  prepareData: (state, isEcosystem) => {
    if (!state.loadingStatus.coins && state.coinDatapointsConfig.length && state.categories.coin) {
      let filteredDatapoints = isEcosystem
        ? state.ecosystemDatapointsConfig.filter(x => !x.default)
        : state.sectorDatapointsConfig.filter(x => !x.default);

      let data = [];

      let coins = state.data.coins?.slice() || [];
      let mainCategoryName = 'Ecosystems';
      let categoryFilter = c => (isEcosystem ? c.name == mainCategoryName : c.name != mainCategoryName);
      let categoryIds = state.categories.coin.filter(categoryFilter).map(c => c.id);

      let allEntities = isEcosystem
        ? state.categories.coin_sub_categories.filter(subCat => subCat.category_id == categoryIds[0])
        : state.categories.coin.filter(categoryFilter).concat(state.categories.coin_sub_categories);

      let coinsInSubCategories = {};
      let coinsInCategories = {};
      coins.forEach(x => {
        x.category_ids?.forEach(y => {
          if (!coinsInSubCategories[y]) {
            coinsInSubCategories[y] = { coins: [] };
          }
          coinsInSubCategories[y].coins.push(x);
        });
        if (isEcosystem) {
          allEntities.forEach(e => {
            let ecosystemName = e.name.split(' ')?.[0]?.toLowerCase();
            if (ecosystemName == x.name?.toLowerCase() || ecosystemName == x.coin_uid?.toLowerCase()) {
              e.coin_uid = x.coin_uid;
            }
          });
        }
      });

      state.categories.coin_sub_categories.forEach(subCat => {
        if (categoryIds.includes(subCat.category_id) && coinsInSubCategories[subCat.id]?.coins) {
          if (!coinsInCategories[subCat.category_id]) {
            coinsInCategories[subCat.category_id] = { coins: [] };
          }
          coinsInCategories[subCat.category_id].coins.push(coinsInSubCategories[subCat.id]?.coins);
        }
      });
      let ecosystemCoins = { near: 'nearprotocol', binance: 'binancecoin', bnb: 'binancecoin', terra: 'luna' };
      allEntities.forEach(entity => {
        let dataItem = {
          id: entity.id,
          [isEcosystem ? 'ecosystem' : 'sector']: isEcosystem ? entity.name.split(' ')[0] : entity.name,
          parentCategory: entity.category_id || null
        };
        if (isEcosystem) {
          dataItem.coin_uid = entity.coin_uid || ecosystemCoins[dataItem.ecosystem.toLowerCase()];
        }
        const coins = dataItem.parentCategory
          ? coinsInSubCategories[entity.id]?.coins || []
          : uniqBy((coinsInCategories[entity.id]?.coins || []).flat(), 'coin_uid');

        filteredDatapoints.forEach(datapoint => {
          if (datapoint.aggregate_by == 'sum') {
            let substituteCol = datapoint.id.includes('news_stories_v2') ? datapoint.id.replace('_v2', '') : null;
            dataItem[datapoint.id] = sumBy(coins, coin => parseFloat(coin[datapoint.id] || coin[substituteCol] || 0));
          } else if (datapoint.aggregate_by?.formula == 'division') {
            let primaryMetric = datapoint.aggregate_by?.primary_metric || 'market_cap';
            dataItem[datapoint.id] =
              sumBy(coins, coin => parseFloat(coin[primaryMetric] || 0)) /
              (sumBy(coins, coin => parseFloat(coin[datapoint.aggregate_by?.secondary_metric] || 0)) || 1);
          } else {
            let weightedMetric =
              datapoint.aggregate_by == 'market_cap' ? 'market_cap' : datapoint.aggregate_by?.secondary_metric;
            if (!weightedMetric) return;
            let weightedMetricSum = sumBy(
              coins,
              coin => parseFloat(coin[datapoint.id] || 0) * parseFloat(coin[weightedMetric] || 0)
            );
            let metricSum = sumBy(coins, coin => parseFloat(coin[weightedMetric] || 0));
            dataItem[datapoint.id] = weightedMetricSum / (metricSum || 1);
          }
        });

        data.push(dataItem);
      });

      if (isEcosystem) {
        state.ecosystemData = data;
      } else {
        state.sectorData = data;
      }
    }
  },
  updateCoinPrice: (state, payload) => {
    const index = state.data.coins.findIndex(x => x.uid == payload.uid);
    state.data.coins[index].price = payload.latestPrice;

    // WIP
    // const index = state.data.original.findIndex(x => x.ticker == payload.ticker);
    // const originalData = state.data.original[index];
    // const lastHrPrice = originalData.price * (parseFloat(originalData.price_return_1_hour) + 1);
    // const last24HrPrice = originalData.price * (parseFloat(originalData.price_return_24_hours) + 1);
    // const last7DPrice = originalData.price * (parseFloat(originalData.price_return_7_day) + 1);
    // const last30DPrice = originalData.price * (parseFloat(originalData.price_return_30_days) + 1);

    // const btcIndex = state.data.coins.findIndex(x => x.ticker == 'BTC');
    // const btcPrice = coinPrice / state.data.coins[btcIndex].price;
    // const lastHrPriceBTC = btcPrice * (parseFloat(state.data.coins[btcIndex].price_return_btc_1_hour) + 1);
    // const last24HrPriceBTC = btcPrice * (parseFloat(state.data.coins[btcIndex].price_return_btc_24_hours) + 1);
    // const last7DPriceBTC = btcPrice * (parseFloat(state.data.coins[btcIndex].price_return_btc_7_day) + 1);
    // const last30DPriceBTC = btcPrice * (parseFloat(state.data.coins[btcIndex].price_return_btc_30_days) + 1);

    // state.data.coins[index].price = payload.latestPrice;
    // state.data.coins[index].price_return_1_hour = lastHrPrice ? payload.latestPrice / lastHrPrice - 1 : '';
    // if (payload.ticker == 'BTC') {
    // }
    // state.data.coins[index].price_return_24_hours = last24HrPrice ? payload.latestPrice / last24HrPrice - 1 : '';
    // state.data.coins[index].price_return_7_days = last7DPrice ? payload.latestPrice / last7DPrice - 1 : '';
    // state.data.coins[index].price_return_30_days = last30DPrice ? payload.latestPrice / last30DPrice - 1 : '';

    // state.data.coins[index].price_return_btc_1_hour = lastHrPriceBTC ? btcPrice / lastHrPriceBTC - 1 : '';
    // state.data.coins[index].price_return_btc_24_hours = last24HrPriceBTC ? btcPrice / last24HrPriceBTC - 1 : '';
    // state.data.coins[index].price_return_btc_7_days = last7DPriceBTC ? btcPrice / last7DPriceBTC - 1 : '';
    // state.data.coins[index].price_return_btc_30_days = last30DPriceBTC ? btcPrice / last30DPriceBTC - 1 : '';
  },
  setSearchMode: (state, payload) => {
    state.searchMode = payload;
  },
  setPath: (state, payload) => {
    const parts = payload.split('.');
    state.path = payload;
    state.firstPath = parts[0];
    state.lastPath = parts[parts.length - 1];
  },
  setTags(state, payload) {
    state.tags = payload;
  },
  setUniversal(state, payload) {
    state.data.universal = payload;
  },
  setCategories(state, payload) {
    state.categories = { ...state.categories, ...payload };
  },
  setSidebarData(state, payload) {
    state.sidebarData = payload;
  },
  setOnChainSidebarData(state, payload) {
    state.onChainSidebarData = payload;
  },
  setSidebar(state, payload) {
    state.sidebars[payload.type] = payload.status;
  },
  setCompanySlugs(state, payload) {
    state.companySlugs = payload;
  },
  setSigdevTagSubgroup(state, payload) {
    state.sigdevTagSubgroup = payload;
  },
  setCompaniesTagSubgroup(state, payload) {
    state.companiesTagSubgroup = payload;
  },
  setCoinsLoader(state, payload) {
    state.loadingStatus.coins = payload;
  },
  setStaticDataLoader(state, payload) {
    state.loadingStatus.static = payload;
  },
  setCoinDatapoints(state, payload) {
    state.coinDatapointsDerivativesConfig = payload.filter(x => x.category == 'derivatives');
    state.coinDatapointsConfig = payload.filter(x => x.category != 'derivatives');
  },
  setSidebarCollapsed(state, collapsedState) {
    state.sidebarCollapsed = collapsedState;
    localStorage.setItem('sidebarCollapsed', collapsedState);
  },
  toggleSidebar(state) {
    state.sidebarCollapsedInt = !state.sidebarCollapsedInt;
  },
  setSidebarState(state, value) {
    state.sidebarCollapsedInt = value;
  },
  setHasSidebar(state, val) {
    state.hasSidebar = val;
  },
  setGasFees(state, payload) {
    state.gasFees = payload;
    state.gasFeesLoader = false;
  },
  openModal(state, payload) {
    state.modal = payload;
  },
  closeModal(state) {
    state.modal = {
      name: '',
      status: false,
      payload: {}
    };
  },
  setMetricTooltips(state, payload) {
    state.metricTooltips = payload;

    const groupedTooltips = groupBy(state.metricTooltips, tooltip => tooltip.metric_key);
    state.coinDatapointsConfig.forEach(datapoint => {
      const tooltip = datapoint.id == 'price' ? groupedTooltips['live_price'] : groupedTooltips[datapoint.id];
      if (tooltip) {
        datapoint.definition = tooltip[0]['definition'];
      }
    });
  },
  setRoles(state, payload) {
    state.team_roles = payload.team;
    state.user_roles = payload.user;
  },
  setUserNotifications(state, payload) {
    state.userNotifications = payload;
  },
  setApiKeys(state, payload) {
    const userApi = payload.user_api
      ? [
          {
            ...payload.user_api,
            name: 'the_tie_api_v1',
            dataset_provider_id: state.datasetProviders.find(x => x.name === 'The Tie API V1')?.id
          }
        ]
      : [];

    state.apiKeys.allApiKeys = [...userApi, ...uniqBy([...payload.user_api_keys, ...payload.team_api_keys], 'id')];
    state.apiKeys.apiKeyOptions = state.apiKeys.allApiKeys.map(x => {
      return {
        id: `{{${x.name}}}`,
        label: `{{${x.name}}}`
      };
    });
  },
  setApiKeysLoading(state, payload) {
    state.apiKeys.loading = payload;
  },
  setDatasetProviders(state, payload) {
    state.datasetProviders = payload;
  },
  changeUserNotificationStatus(state, payload) {
    if (payload.id == 0) {
      state.userNotifications = state.userNotifications.map(x => {
        let a = x;
        a.read = true;
        return a;
      });
    } else {
      let index = state.userNotifications.findIndex(x => x.id == payload.id);
      state.userNotifications[index].read = payload.status;
    }
  },
  addNewUserNotification(state, payload) {
    state.userNotifications.unshift(payload);
  },
  removeUserNotification(state, id) {
    state.userNotifications = state.userNotifications.filter(x => x.id != id);
  },
  setWidgetOutputChannel(state, payload) {
    state.widgetOutputChannels[payload.channel] = payload.value;
  },
  clearWidgetOutputChannel(state, channel) {
    state.widgetOutputChannels[channel] = null;
  },
  setConfirmationDialogOptions(state, payload) {
    let defaultOptions = state.confirmationDialogOptions;
    let modifiedOptions = {};
    if (typeof payload === 'string') {
      modifiedOptions = { ...defaultOptions, message: payload, show: true };
    } else {
      modifiedOptions = { ...defaultOptions, ...payload, show: true };
    }
    state.confirmationDialogOptions = modifiedOptions;
  },
  resetConfirmationDialogOptions(state) {
    state.confirmationDialogOptions = {
      show: false,
      resolve: null,
      title: 'Action Required',
      message: 'Are you sure?',
      confirmText: 'Proceed',
      rejectText: 'Cancel'
    };
  },
  resolveConfirmation(state, payload) {
    state.confirmationDialogOptions.resolve = payload;
  },
  setCoinChain(state, payload) {
    state.coinChain = payload;
  },
  setLaunchMessengerCancelled(state, payload) {
    state.launchMessengerCancelled = payload;
  },
  setOnchainAddresses(state, payload) {
    state.onchainAddresses = payload;
  },
  setOnchainAddressesModal(state, payload) {
    state.onchainAddressesModal = payload;
  },
  setOnchainFolderModal(state, payload) {
    state.onchainFolderModal = payload;
  },
  setUnlockCoins: (state, coins) => {
    state.unlockCoins = coins;
  },
  setWatchlistAlertsModal(state, payload) {
    state.watchlistAlertsModal = payload;
  },
  setActiveDashboard: (state, payload) => {
    state.activeDashboard = payload;
  }
};
